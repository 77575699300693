import { 
  SEND_SURVEY_ERROR,
  SEND_SURVEY_LOADING,
  SEND_SURVEY_REQUEST,
  SEND_SURVEY_SUCCESS
  } from './Constant';
  
  export function sendSurveyRequest (data) {
    return {
      type: SEND_SURVEY_REQUEST,
      payload: data,
    }
  }

  export function sendSurveySuccess (data) {
    return {
      type: SEND_SURVEY_SUCCESS,
      payload: data,
    }
  }

  export function sendSurveyLoading (data) {
    return {
      type: SEND_SURVEY_LOADING,
      payload: data,
    }
  }

  export function sendSurveyError (data) {
    return {
      type: SEND_SURVEY_ERROR,
      payload: data,
    }
  }