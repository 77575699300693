import { fromJS } from 'immutable';
import {
  SEND_SURVEY_ERROR,
  SEND_SURVEY_LOADING,
  SEND_SURVEY_SUCCESS,
} from './Constant';

export const initialState = fromJS({
  isSurveyRequestSuccess:false,
  loading:false,
  isError:false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SEND_SURVEY_SUCCESS:
      return state.set('isSurveyRequestSuccess', action.payload);
    case SEND_SURVEY_LOADING:
      return state.set('loading', action.payload);
    case SEND_SURVEY_ERROR:
      return state.set('isError', action.payload);
    default:
      return state;
  }
}

export default reducer;
