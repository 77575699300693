import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import TextField from './TextField';
import Button from './Button';
// import VEMAS from "./images/VEMASLOGO.png";
// import Loader from './Loader';
import "@ui5/webcomponents/dist/Icon";
import "@ui5/webcomponents-icons/dist/Assets";
import { makeSelectSurveyRequestError, makeSelectSurveyRequestLoading, makeSelectSurveyRequestSuccess } from './store/Selector';
import { sendSurveyError, sendSurveyRequest } from './store/Action';
import './App.css';
// import WarningModal from './warningModal';

class Survey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Comments:'',
            Id:0,
            Rating:0,
            initial:true,
            isInvalid: false
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const { match: { params }  } = prevProps;
        if(params && params.id && params.rating && prevState.initial){
            return {
                Id: parseInt(params.id),
                Rating: parseInt(params.rating),
                initial: false
            }
        }
        return null
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = () => {
        if(this.state.Rating === 0){
            this.setState({
                isInvalid: true
            })
            return
        }
        const params = {
            Id:this.state.Id,
            RatingId:this.state.Rating,
            Notes:this.state.Comments
        }
        // console.log(params)
        this.props.dispatch(sendSurveyRequest(params))
    }

    handleRating = (value) => {
        if(value > 0){
            this.setState({
                isInvalid: false
            })
        }
        this.setState({
            Rating: value
        })
    }

    componentDidUpdate (){
        if(this.props.isError){
            setTimeout(
                () => {
                    this.props.dispatch(sendSurveyError(false))
                }, 
                3000
              );
        }
    }

    render() {
        const { 
            Rating,
            isInvalid
        } =  this.state;

        const { 
            loading,
            isError,
            isSurveyRequestSuccess
        } =  this.props;
        
        return (
            <div className="survey-form">
                <h4>HELP US TO HELP YOU</h4>
                <p>Your feedback help us to transform our workshop to what you want to see.</p>
                <div className="line"></div>
                <div className="rating" onClick={() => this.handleRating(5)}>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 5 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 5 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 5 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 5 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 5 ? "favorite" : "unfavorite"}></ui5-icon>
                </div> 
                <div className="rating" onClick={() => this.handleRating(4)}>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 4 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 4 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 4 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 4 ? "favorite" : "unfavorite"}></ui5-icon>
                </div>
                <div className="rating" onClick={() => this.handleRating(3)}>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 3 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 3 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 3 ? "favorite" : "unfavorite"}></ui5-icon>
                </div>
                <div className="rating" onClick={() => this.handleRating(2)}>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 2 ? "favorite" : "unfavorite"}></ui5-icon>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 2 ? "favorite" : "unfavorite"}></ui5-icon>
                </div> 
                <div className="rating" onClick={() => this.handleRating(1)}>
                    <ui5-icon class="samples-margin" name="favorite" id={Rating === 1 ? "favorite" : "unfavorite"}></ui5-icon>
                </div>
                     {isSurveyRequestSuccess ? 
                        <div>
                            <h3>THANK YOU</h3>
                        </div> :
                <div>
                    <span>
                    { 
                        Rating === 5 ? "Excellent" : 
                        Rating === 4 ? "Good" :
                        Rating === 3 ? "Average" :
                        Rating === 2 ? "Bad" :
                        Rating === 1 ? "Worst":"" 
                    }
                    </span>
                <TextField
                    controlId="Comments"
                    text="Comments"
                    type="text"
                    onBlur={this.handleChange}
                    maxLength={250}
                    defaultValue={this.state.Comments}
                    as="textarea"
                    row={50}
                    style={{ height: 100 }}
                    isInvalid={isInvalid}
                />
                {isError  ? <p className="messageError">Something went wrong.Try again</p> :
                    <div className="submit">
                        <Button
                            variant="primary" 
                            text ='SUBMIT'
                            type="submit"
                            onClick={this.handleSubmit}
                            size="lg"
                            disabled={loading}
                        />
                    </div>
                }
                </div>}
            </div>
        )    
    }
}

Survey.propTypes = {
    loading: PropTypes.bool,
    isError: PropTypes.bool,
    isSurveyRequestSuccess: PropTypes.any
}

const mapStateToProps = createStructuredSelector({
    loading: makeSelectSurveyRequestLoading(),
    isError: makeSelectSurveyRequestError(),
    isSurveyRequestSuccess: makeSelectSurveyRequestSuccess(),
});
  
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
}
  
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
  
export default (compose(withConnect)(Survey));
// export default Survey