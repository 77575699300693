import axios from 'axios'

const baseURL = process.env.REACT_APP_API_URL

export function addSurvey(data){
  const parameters = JSON.stringify(data)
  return axios.patch(baseURL+'surveys/'+data.Id, parameters, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : 'VGLkM6Y+WJ2Wm7VUBsjS1A=='
    }
  })
}