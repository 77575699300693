import { createSelector } from 'reselect';

const selectSurveyDomain = state => state.survey;

const makeSelectSurveyRequestSuccess =() =>
  createSelector(
    selectSurveyDomain,
    surveyState => 
      surveyState ? surveyState.get('isSurveyRequestSuccess') : false
);

const makeSelectSurveyRequestLoading =() =>
  createSelector(
    selectSurveyDomain,
    surveyState => 
      surveyState ? surveyState.get('loading') : false
);

const makeSelectSurveyRequestError =() =>
  createSelector(
    selectSurveyDomain,
    surveyState => 
      surveyState ? surveyState.get('isError') : false
);

const makeSelectSurveyAlreadyExists =() =>
  createSelector(
    selectSurveyDomain,
    surveyState => 
      surveyState ? surveyState.get('isUserAlreadyExists') : false
);

export {
  makeSelectSurveyRequestSuccess,
  makeSelectSurveyRequestLoading,
  makeSelectSurveyRequestError,
  makeSelectSurveyAlreadyExists
}
