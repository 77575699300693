import React from 'react';
import './App.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Survey from './SurveyForm';

function App() {
  return (
    <HashRouter>
      <div className="App">
          <Switch>
            <Route path='/:id/:rating' component={ Survey } />
        </Switch>
      </div>
    </HashRouter>
  );
}

export default App;
