import { takeLatest, put, call } from 'redux-saga/effects';
import { SEND_SURVEY_REQUEST } from './store/Constant';
import { addSurvey } from './service';
import * as actions from './store/Action';

export function* sendSurveyRequest(data){
  try{
    yield put(actions.sendSurveyLoading(true));
    yield put(actions.sendSurveyError(false));
    const response = yield call(addSurvey,data.payload);
    if(response && response.data && response.data.statusCode && response.data.statusCode === 200 ){
      // console.log(response)
      yield put(actions.sendSurveySuccess(true))
    }else{
      yield put(actions.sendSurveyError(true));
    }  
    yield put(actions.sendSurveyLoading(false));
  }catch(error){
      yield put(actions.sendSurveyError(true));
    yield put(actions.sendSurveyLoading(false));
  }
}


  export default function* rootSaga(){
    yield takeLatest(SEND_SURVEY_REQUEST,sendSurveyRequest);
  }